const actions = {
  SET_STATE: 'business/SET_STATE',
  FETCH_ALL_BUSINESS: 'business/FETCH_ALL_BUSINESS',
  FETCH_ALL_ONBOARDING_REVIEW_BUSINESS: 'business/FETCH_ALL_ONBOARDING_REVIEW_BUSINESS',
  SET_STATE_DETAIL: 'business/SET_STATE_DETAIL',
  FETCH_BUSINESS_DETAIL: 'business/FETCH_BUSINESS_DETAIL',
  SET_STATE_USERS: 'business/SET_STATE_USERS',
  ADD_BUSINESS_NOTES: 'business/ADD_BUSINESS_NOTES',
  LIST_BUSINESS_NOTES: 'business/LIST_BUSINESS_NOTES',
  ACTIVE_DEACTIVE_BUSINESS: 'business/ACTIVE_DEACTIVE_BUSINESS',
  ACTIVE_DEACTIVE_USERS: 'business/ACTIVE_DEACTIVE_USERS',
  FORCE_UPDATE_PASSWORD: 'business/FORCE_UPDATE_PASSWORD',
  DELETE_STRIPE_ACCOUNT: 'business/DELETE_STRIPE_ACCOUNT',
  CHANGE_STATEMENT_DESCRIPTOR: 'business/CHANGE_STATEMENT_DESCRIPTOR',
  SYNC_BUSINESS_STRIPE_DATA: 'business/SYNC_BUSINESS_STRIPE_DATA',
  CLONE_BUSINESS: 'business/CLONE_BUSINESS',
  RESTRICT_BUSINESS: 'business/RESTRICT_BUSINESS',
  TOGGLE_ONBOARDING: 'business/TOGGLE_ONBOARDING',
  TOGGLE_CAPABILITIES: 'business/TOGGLE_CAPABILITIES',
  SET_BUSINESS_CAPABILITIES: 'business/SET_BUSINESS_CAPABILITIES',
  FETCH_BUSINESS_CAPABILITIES: 'business/FETCH_BUSINESS_CAPABILITIES',
  ADJUST_BUSINESS_REWARD_POINTS: 'business/ADJUST_BUSINESS_REWARD_POINTS',
  MANAGE_BUSINESS_PROVIDER: 'business/MANAGE_BUSINESS_PROVIDER',
  MIGRATE_DATA_FROM_PEYMYNT: 'business/MIGRATE_DATA_FROM_PEYMYNT',
  FETCH_BUSINESS_PROVIDER_LOGS: 'business/FETCH_BUSINESS_PROVIDER_LOGS',
  SET_BUSINESS_WEBHOOK_LOADING: 'business/SET_BUSINESS_WEBHOOK_LOADING',
  SET_BUSINESS_WEBHOOK_LOGS: 'business/SET_BUSINESS_WEBHOOK_LOGS',
  FETCH_BUSINESS_PROVIDER_ERROR_LOGS: 'business/FETCH_BUSINESS_PROVIDER_ERROR_LOGS',
  SET_BUSINESS_ERROR_LOADING: 'business/SET_BUSINESS_ERROR_LOADING',
  SET_BUSINESS_ERROR_LOGS: 'business/SET_BUSINESS_ERROR_LOGS',
  SET_BUSINESS_PAYMENT_SETTINGS: 'business/SET_BUSINESS_PAYMENT_SETTINGS',
  FETCH_BUSINESS_PAYMENT_SETTINGS: 'business/FETCH_BUSINESS_PAYMENT_SETTINGS',
  UPDATE_BUSINESS_PAYMENT_SETTINGS: 'business/UPDATE_BUSINESS_PAYMENT_SETTINGS',
}

export default actions
