const actions = {
  SET_STATE: 'logs/SET_STATE',
  SET_WEBHOOK_LOGS: 'logs/SET_WEBHOOK_LOGS',
  SET_ERROR_LOGS: 'logs/SET_ERROR_LOGS',
  FETCH_ALL_WEBHOOK_LOGS: 'logs/FETCH_ALL_WEBHOOK_LOGS',
  FETCH_ALL_ERROR_LOGS: 'logs/FETCH_ALL_ERROR_LOGS',
  SET_WEBHOOK_LOADING: 'logs/SET_WEBHOOK_LOADING',
  SET_ERROR_LOADING: 'logs/SET_ERROR_LOADING',
}

export default actions
