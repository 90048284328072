const actions = {
  SET_STATE: 'users/SET_STATE',
  START_FETCH_USER_SESSION: 'user/START_FETCH_USER_SESSION',
  SET_USER_SESSION: 'user/SET_USER_SESSION',
  FETCH_ALL_USERS: 'users/FETCH_ALL_USERS',
  FETCH_USER_USER: 'users/FETCH_USER_USER',
  LIST_USERS_NOTES: 'users/LIST_USERS_NOTES',
  ADD_USERS_NOTES: 'users/ADD_USERS_NOTES',
  REQUEST_VERIFICATION: 'users/REQUEST_VERIFICATION',
  ACTIVE_DEACTIVE_USERS: 'users/ACTIVE_DEACTIVE_USERS',
  RESET_PASSWORD_USERS: 'users/RESET_PASSWORD_USERS',
  ASSUME_USER: 'user/Assume_USER',
  CHANGE_CONNECTED_EMAIL: 'users/CHANGE_CONNECTED_EMAIL',
  CHANGE_2FA_STATUS: 'users/CHANGE_2FA_STATUS',
  CHANGE_MOBILE_NUMBER: 'users/CHANGE_MOBILE_NUMBER',
  FETCH_USER_SESSION: 'user/FETCH_USER_SESSION',
  SIGN_OUT_SESSION: 'user/SIGN_OUT_SESSION',
}

export default actions
